import React from 'react'
import { graphql } from 'gatsby'

import { Fade, Layout, Preview, Previews } from '../components'
import { defaultSd } from '../../utils/pick-quality'

class Work extends React.Component {

  title = 'Work'

  renderSpan(key, node) {
    if (node[key]) {
      return (
        <span>
          {node[key]}
          <br/>
        </span>
      )
    } else {
      return ''
    }
  }

  render() {
    return (
      <Layout page={this}>
        <Previews>
          <div className="work">
            <div className="work__main">
              {
                [this.props.data.datoCmsWork.videos[0]].map((node) => (
                  <Fade key={node.id} to={`/work/${node.video.slug}`} className="work__preview refresh">
                    <Preview className="w-full h-full" cover={`${node.video.cover.url}?auto=format&fit=min&w=1600`} src={node.video.thumbnailMp4Url}>
                      <h2 className="preview__on-hover-title font-head text-28 lg:text-38 uppercase tracking-wide leading-tight">{node.title}</h2>
                      <span className="preview__on-hover font-main text-12 md:text-16 uppercase leading-tight">{this.renderSpan('subtitle', node)}</span>
                    </Preview>
                  </Fade>
                  ))
              }
            </div>

            <div className="work__grid">
              {
                (this.props.data.datoCmsWork.videos.length > 1) && this.props.data.datoCmsWork.videos.slice(1).map((node) => (
                  <div key={node.id} className="w-full md:w-1/2">
                    <div className="work__item">
                      <Fade to={`/work/${node.video.slug}/`} className="work__preview refresh">
                        <Preview className="w-full h-full" cover={`${node.video.cover.url}?auto=format&fit=min&w=800`} src={defaultSd(node.video)} preload="metadata">
                          <h2 className="preview__on-hover-title font-head text-24 lg:text-28 uppercase tracking-wide leading-tight">{node.title}</h2>
                          <span className="preview__on-hover font-main text-12 lg:text-14 uppercase leading-tight">{this.renderSpan('subtitle', node)}</span>
                        </Preview>
                      </Fade>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </Previews>
      </Layout>
    )
  }
}

export default Work

export const query = graphql`
  query workQuery {
    datoCmsWork {
      videos {
        id
        title
        subtitle
        video {
          title
          slug
          thumbnailMp4Url
          thumbnailMp4UrlSd
          cover {
            url
          }
        }
      }
    }
  }
`;